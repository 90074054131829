.customer-page {
   padding: 2.6rem 0;
   padding-right: 2.5rem;

   .search-filter {
      padding-bottom: 3rem;

      .search {
         .input-group {
            input {
               background: #FFFFFF;
               border-radius: 10px 0 0 10px;
               border: none;
               padding: .7rem 4rem .7rem 1rem;

               &::placeholder {
                  font: normal .8rem 'SofiaPro';
                  color: #C4C4C4;
               }
            }

            .collaps {
               display: flex;
               align-items: center;
               margin-left: 1rem;
               // margin-bottom: 2rem;
                  
               .collaps-item {
                  background: #FFFFFF;
                  border-radius: 10px;
                  font: normal .9rem 'SofiaPro';
                  color: #606060;
                  padding: .7rem 1.5rem;
                  cursor: pointer;
   
                  .status-list {
                     top: 100%;
                     left: 0;
                     right: 0;
                     border-radius: 10px;
                     background: #ffffff;
                     box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                     
                     ul {
                        list-style: none;
                        margin-bottom: 0;
                        padding-left: 0;
                        
                        li {
                           a {
                              font: normal .85rem 'SofiaPro';
                              border-radius: 10px;
                              color: #000000;
                              padding: .8rem 1.5rem;
                              
                              &:hover {
                                 background: #3377EF;
                                 color: #FFFFFF;
                              }
                           }
                        }
                     }
                  }
   
                  .date {
                     background: #ffffff;
                     top: 100%;
                     left: 0;
                     border-radius: 10px;
                     box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                  }
               }
            }

            .i-x {
               margin-left: -3rem;
            }

            .i-search {
               background: #3377EF;
               color: #fff;
               border-radius: 0px 10px 10px 0px;
               padding: .7rem 1rem;
               margin-left: 2rem;
               cursor: pointer;
               transition: all 0.3s ease-in;
               
               &:hover {
                  background: #3377EF98;
               }
            }
         }
      }

      .filter {
         .csv {
            font-size: 1rem !important;
            font-weight: 400;
            color: #3377EF;
            white-space: nowrap;
            padding: .8rem 1rem;
            letter-spacing: 0.03rem;
            border-radius: 6px;
            border: 1px solid #3377EF;
            background-color: rgba(255, 255, 255, 0.05);
            transition: .2s;

            &:hover {
               background-color: rgba(255, 255, 255, 0.03);
            }
         }
         .menu {
            border-radius: 13px;
            font: 600 .8rem 'SofiaPro';
            padding: .8rem 1rem;
            color: #3377EF;
            cursor: pointer;

            &:first-child {
               border: 1px solid #3377EF;
               transition: all .4s ease;
               
               &:hover {
                  background: #3377EF;
                  color: #FFFFFF;
               }
            }
         }

         .menu2 {
            background: #3377EF;
            color: #ffffff;
            margin-left: 1rem;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               border: 1px solid #3377EF;
            }
         }

         .collaps {
            display: inline-flex;
            position: absolute;
            left: 0;
            top: 18%;
            margin-bottom: 2rem;
               
            .collaps-item {
               background: #FFFFFF;
               border-radius: 10px;
               font: normal .9rem 'SofiaPro';
               color: #606060;
               padding: .7rem 1.5rem;
               cursor: pointer;

               .status-list {
                  top: 100%;
                  left: 0;
                  right: 0;
                  border-radius: 10px;
                  background: #ffffff;
                  box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                  
                  ul {
                     list-style: none;
                     margin-bottom: 0;
                     padding-left: 0;
                     
                     li {
                        a {
                           font: normal .85rem 'SofiaPro';
                           border-radius: 10px;
                           color: #000000;
                           padding: .8rem 1.5rem;
                           
                           &:hover {
                              background: #3377EF;
                              color: #FFFFFF;
                           }
                        }
                     }
                  }
               }

               .date {
                  background: #ffffff;
                  top: 100%;
                  left: 0;
                  border-radius: 10px;
                  box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
               }
            }
         }
      }
   }

   .customer-list {
      background: #FFFFFF;
      border-radius: 20px;

      .profile {
         padding-left: 2rem !important;

         thead {
            
            tr {
               border-bottom: 1px solid rgba(0,0,0,.1);
               
               th {
                  font: 600 .85rem 'SofiaPro';
                  color: #000000;
                  padding: 1.5rem 1rem 1rem 1.5rem;
               }
            }
         }
         
         tbody {
            .d-table-row {
               &:nth-child(odd) {                  
                  background: #fbfcff;
               }

               th ,td {
                  font: normal .8rem 'SofiaPro';
                  // padding: 0.85rem 1.2rem;
                  // padding: 1.2rem;
                  color: #000000;
               }

               th {
                  span {
                     padding-left: .6rem;
                  }
               }

               &:hover {
                  background: #F7F8FC;
               }

               .form-check {
                  padding: 0;

                  input {
                     margin: 0;
                     margin-left: -.8rem;
                  }
               }
            }

            .t-row {
               th,td {
                  padding: 1.7rem 0 1.7rem 2rem;
               }
            }
         }
      }
   }

   .sum-table {
      background: #FFFFFF;
      border-radius: 25px;
      // padding-left: 2rem;

      .table {
         margin-bottom: 0;

         .d-table-row {
            th, td {
               font: 600 .8rem 'SofiaPro';
               color: #000000;
               padding-top: 1.3rem;
               padding-bottom: 1.3rem;
            }

            th {
               padding-left: 2rem;
            }
         }
      }
   }

   .pagination {
      ul {
         list-style: none;
         background: #FFFFFF;
         border-radius: 4px;
         margin-bottom: 0;
         padding-left: 0;
         
         li {
            font: normal .6rem "SofiaPro";
            color: #000000;
            padding: 0.2rem 0;
            
            a {
               border-radius: 4px;
               color: #000000;
               padding: 0.2rem .8rem;
               
               &.active {
                  color: #FFFFFF;
                  background: #3377EF;
               }

               &:hover {
                  background: #3377EF98;
                  border-radius: 4px;
                  color: #FFFFFF;
               }
            }
         }
      }

      a.arrow {
         background: #3377EF;
         border-radius: 50%;
         height: 1.5rem;
         width: 1.5rem;
         
         &:hover {
            background: #3377EF98;
         }
      }
   }
}