.settings-page {
   padding: 2.6rem 0;
   padding-right: 2.5rem;
   background: #F7F8FC;

   .history {
      margin-bottom: 2rem;

      ul {
         list-style: none;
         padding-left: 0;
         margin: 0;

         li {
            position: relative;

            &:not(:last-child) {
               margin-right: 2rem;
            }

            div {
               font: 600 .9rem 'SofiaPro';
               color: #C4C4C4;
               cursor: pointer;

               &:hover {
                  color: #3377EF98;
               }

               &.active {
                  color: #3377EF;

                  &::before {
                     content: "";
                     height: 3px;
                     width: 40%;
                     background: #3377EF;
                     position: absolute;
                     left: 0;
                     top: 1.3rem;
                     transition: 0.5s;
                  }

                  &:hover::before {
                     width: 100%;
                  }
               }
            }
         }
      }

      .price {
         font: 600 1.05rem 'SofiaPro';
         color: #000000;
      }
   }

   .button {
      background: #3377EF;
      border-radius: 10px;
      font: normal .8rem 'SofiaPro';
      color: #FFFFFF;
      padding: .8rem 2.5rem;
      cursor: pointer;
      width: max-content;
      
      &:hover {
         background: #3377EF98;
      }
   }

   .customer-list {
      background: #FFFFFF;
      border-radius: 20px;

      .profile {
         padding-left: 2rem !important;

         thead {
            
            tr {
               border-bottom: 1px solid rgba(0,0,0,.1);
               
               th {
                  font: 600 .85rem 'SofiaPro';
                  color: #000000;
                  padding: 1.5rem 1rem 1rem 1.5rem;
               }
            }
         }
         
         tbody {
            .d-table-row {

               .more {
                  border: 1px solid #3377EF;
                  border-radius: 5px;
                  color: #3377EF;
                  padding: .6rem .85rem;
                  
                  .dropdownmenu {
                     //display: none;
                     background: #FFFFFF;
                     box-shadow: 0px 8px 24px rgba(51, 119, 239, 0.15);
                     left: 0;
                     right: 0;
                     top: 2.4rem !important;
                     z-index: 2;
                     
                     li {
                        list-style: none;

                        &:first-child {
                           a {
                              padding: .6rem 0;
                              color: #3377EF;
   
                              &:hover {
                                 background: #3377EF;
                                 color: #ffffff;
                              }
                           }
                        }

                        &:last-child {
                           a {
                              padding: .6rem 0;
                              color: #E2574C;
   
                              &:hover {
                                 background: #E2574C;
                                 color: #ffffff;
                              }
                           }
                        }
                     }
                  }

                  // &:hover {
                  //    background: #ffffff;

                  //    .dropdownmenu {
                  //    display: block;
                  //    }
                  // }
            
                  // &.active {
                  //    background: #3377EF !important;
                  //    color: #ffffff;
                  // }
               }

               // .remove {
               //    background: rgba(244, 67, 54, 0.2);
               //    border-radius: 5px;
               //    font: normal .8rem 'SofiaPro';
               //    color: #E2574C;
               //    padding: .6rem 1.2rem;

               //    &:hover {
               //       background: #E2574C;
               //       color: #ffffff;
               //    }
               // }

               &:nth-child(odd) {                  
                  background: #fbfcff;
               }

               th ,td {
                  font: normal .8rem 'SofiaPro';
                  // padding: 0.85rem 1.2rem;
                  padding: 1.2rem;
                  color: #000000;
               }

               th {
                  span {
                     padding-left: .6rem;
                  }
               }

               &:hover {
                  background: #F7F8FC;
               }
            }

            .t-row {
               th,td {
                  padding: 1.7rem 0 1.7rem 2rem;
               }
            }
         }
      }
   }

   .pagination {
      ul {
         list-style: none;
         background: #FFFFFF;
         border-radius: 4px;
         margin-bottom: 0;
         padding-left: 0;
         
         li {
            font: normal .6rem "SofiaPro";
            color: #000000;
            padding: 0.2rem 0;
            
            a {
               border-radius: 4px;
               color: #000000;
               padding: 0.2rem .8rem;
               
               &.active {
                  color: #FFFFFF;
                  background: #3377EF;
               }

               &:hover {
                  background: #3377EF98;
                  border-radius: 4px;
                  color: #FFFFFF;
               }
            }
         }
      }

      a.arrow {
         background: #3377EF;
         border-radius: 50%;
         height: 1.5rem;
         width: 1.5rem;
         
         &:hover {
            background: #3377EF98;
         }
      }
   }

   .role-permission {

      .super-admin, .financial-analyst {
         background: #FFFFFF;
         border-radius: 20px;

         h4.h4 {
            font: normal 1.1rem 'SofiaPro';
            color: #000000;
            padding: 1.4rem 1.5rem .5rem;
         }

         .__form {
            max-width: 18rem;
            width: 100%;

            .label {
               font: normal .95rem 'SofiaPro';
               color: #000000;
            }

            .input111 {
               padding: .8rem 1rem;
               margin-top: .2rem;
               font: normal .8rem 'SofiaPro';
               color: #000000;
               border-radius: 4px;
               border: 1px solid #C4C4C4 !important;
            }

            button {
               margin: 0;
               padding: 0;
               
               .checking {
                  padding: 1rem;
                  transition: all 0.3s ease-in;
               }
            }
            .pass-icon {
               color: #C4C4C4;
            }
         }

         p.p {
            font: normal .8rem 'SofiaPro';
            color: #000000;
         }

         h4 {
            font: normal .85rem 'SofiaPro';
            color: #000000;
            padding: 1.4rem 1.2rem;
         }

         .users-role {
            padding: 1rem 1.2rem;

            p {
               font: normal .75em 'SofiaPro';
               color: #606060;
            }

            .user {
               .add, .remove, .disburse-loan {
                  font: normal .65rem 'SofiaPro';
                  background: rgba(51, 119, 239, 0.1);
                  border-radius: 4px;
                  color: #3377EF;
                  cursor: pointer;
                  transition: all .3s ease-in-out;
                  
                  &:hover {
                     background: rgba(51, 119, 239, 0.2);                     
                  }
               }
            }
         }

         .display {
            float: right;
            padding: 0rem 1.2rem;
            
         }

         .super-admin, .display {
            // background: #FFFFFF;
            // border-radius: 20px;
            .dropdownmenu {
               //display: none;
               background: #FFFFFF;
               box-shadow: 0px 8px 24px rgba(51, 119, 239, 0.15);
               left: 0;
               right: 0;
               top: 2.4rem !important;
               z-index: 2;
               
               li {
                  list-style: none;

                  &:first-child {
                     .add-new {

                        a {
                           font: normal .8rem 'SofiaPro';
                           padding: .6rem .8rem;
                           color: #3377EF;
                           // border-radius: 4px;
   
                           &:hover {
                              background: #3377EF;
                              color: #ffffff;
                           }
                        }
                     }
                  }

                  &:last-child{

                     a.text-center {
                        font: normal .8rem 'SofiaPro';
                        padding: .6rem .8rem;
                        color: #E2574C;
                        // border-radius: 4px;
   
                        &:hover {
                           background: #E2574C;
                           color: #ffffff;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}

.ReactModal__Overlay--after-open, .ReactModal__Overlay {
   background-color: rgba(0, 0, 0, 0.3) !important;
   z-index: 20;

   .ReactModal__Content, .ReactModal__Content--after-open {
      border: none !important;
   }

   .__form {
      padding: 1rem 1.5rem;
      // width: 100%;

      h4 {
         font: 600 1.15rem 'SofiaPro';
         color: #000000;
      }
      p {
         font: normal .72rem 'SofiaPro';
         color: #000000;
      }

      .label {
         font: normal .85rem 'SofiaPro';
         color: #000000;
      }

      .input11 {
         border: 1px solid #C4C4C4 !important;
         border-radius: 4px;
         background: none;
         padding: .6rem 12rem .6rem 1.3rem;
         // margin-bottom: .2rem;
         // margin-top: .2rem;

         &::placeholder {
            font: normal .8rem 'SofiaPro';
            color: #C4C4C4;
         }
      }

      .input22 {
         border: 1px solid #C4C4C4 !important;
         border-radius: 4px;
         background: none;
         padding: .6rem 12rem .6rem 1.3rem;
         margin-top: .2rem;

         &::placeholder {
            font: normal .8rem 'SofiaPro';
            color: #C4C4C4;
         }
      }
      
      .input222 {
         font: normal .8rem 'SofiaPro';
         color: #C4C4C4;
         border: 1px solid #C4C4C4 !important;
         border-radius: 4px;
         background: none;
         margin-top: .2rem;
         padding: .8rem 1.2rem;
      }

      .error {
         font: 600 .8rem 'SofiaPro';
         color: #ef3333;
      }

      .form-group {
         margin-bottom: 1.7rem;

         .list {
            background: #777;
            top: 100%;
            left: 0;
            right: 0;
            
            ul {
               list-style: none;
               padding-left: 0;
               margin-bottom: 0;
               
               li {
                  color: #606060;
                  background: #d9e4f7;
                  padding: .8rem 1.3rem;
                  border: none !important;
                  
                  &:hover {
                     background: #d9e4f798;                     
                  }
               }
            }
         }
      }

      button {
         border: none;
         background: none;
         margin: .8rem 0 !important;
         // background: #777;

         .submit-btn {
            background: #3377EF;
            border-radius: 8px;
            font: normal .8rem 'SofiaPro';
            color: #ffffff;
            width: max-content;
            
            &:hover {
               background: #3377EF98;
            }
         }
      }

      textarea {
         resize: none;
      }
   }

   .content {
      padding: 0 3rem 1rem;

      .credited {
         margin: 1.5rem auto;
      }

      .deleted {
         margin: 0.8rem auto;
      }

      h3 {
         font: 600 1.1rem 'SofiaPro';
         color: #606060;
      }

      p {
         font: normal .8rem 'SofiaPro';
         color: #000000;
         padding: 0 8rem;
         margin-bottom: 4rem;
      }

      button {
         padding: 0;
         border: none;
         background: none;
         margin: 3.6rem auto;

         a {
            border: 1px solid #3377EF;
            border-radius: 8px;
            font: 600 .8rem 'SofiaPro';
            color: #3377EF;
            padding: .7rem 2rem;

            &:hover {
               background: #3377EF98;
               color: #ffffff;
               border: none;
            }
         }
      }

      .loader {
         border: 16px solid #f3f3f3; /* Light grey */
         border-top: 16px solid #3498db; /* Blue */
         border-radius: 50%;
         width: 120px;
         height: 120px;
         animation: spin 2s linear infinite;
      }
      
      @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
      }

   }

   div.role {

      h3.role {
      font: 600 1.5rem 'SofiaPro';
      }

      p {
         font: normal 1rem 'SofiaPro';
         padding: 0 2rem;
         margin-bottom: 2rem;
      }

      .action-btn {

         button.role-btn {
            font: normal 1rem 'SofiaPro';
            border-radius: 8px;
            padding: .9rem 2rem;
            transition: all 0.3s ease-in;
         }

         button.cancel {
            color: #3377EF;
            border: 1px solid #3377EF !important;

            &:hover {
               color: #ffffff;
            background: #3377EF;
            border: none;
            }
         }

         button.delete {
            color: #ffffff;
            background: #ef3333;
            
            &:hover {
               background: #ef333398;
            }
         }
      }
   }
}