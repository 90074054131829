.forgot-password-page {

   .right {

      .__form {
         width: 492px !important;
         margin-top: 6.5%;
         background: #FFFFFF;
         border-radius: 20px;
         padding-left: 2.9rem !important;
         padding-right: 3rem !important;

         .label {
            font: normal .8rem 'SofiaPro';
            line-height: 24px;
            color: #000000;
         }

         input {
            border: 1px solid #C4C4C4 !important;
            border-radius: 4px !important;
            line-height: 2.5rem;
            margin-top: .8rem;

            &::placeholder {
               font: normal .8rem 'SofiaPro';
               color: #C4C4C4;
               padding: 1.5rem !important;
            }
         }

         .__link {
            font: 600 .8rem 'SofiaPro';
            color: #3377EF;
            text-align: center;
         }

         button {
            border: none;
            //background: none;
            padding: 0;
            background: #3377EF;
               border-radius: 10px;
               font: 500 .8rem 'SofiaPro';
               color: #FFFFFF;
               
               &:hover {
                  background: #3377EF98;
               }
            
            // a {
            //    background: #3377EF;
            //    border-radius: 10px;
            //    font: 500 .8rem 'SofiaPro';
            //    color: #FFFFFF;
               
            //    &:hover {
            //       background: #3377EF98;
            //    }
            // }
         }

         .error {
            font: 600 .8rem 'SofiaPro';
            color: #ef3333;
         }
      }
   }
}