/* body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    & > * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    #root {
      height: 100%;
      width: 100%;
      display: flex;
    }
  }
}

.ReactModal__Content, .ReactModal__Content--after-open {
  border: none !important;
  max-height: 90vh;
  height: fit-content;
  overflow-y: scroll;

  &::-webkit-scrollbar {
      width: 6px;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #aaa;
      border-radius: 8px;
  }
}
