.merchant-page {
   .history {
      padding-top: 1rem;
      margin-bottom: 2rem;

      ul {
         list-style: none;
         padding-left: 0;
         margin-left: 0;
         margin-right: 17rem;

         li {
            position: relative;

            &:first-child {
               margin-right: 2rem;
            }

            div {
               font: 600 .9rem 'SofiaPro';
               color: #C4C4C4;
               cursor: pointer;

               &:hover {
                  color: #3377EF98;
               }

               &.active {
                  color: #3377EF;

                  &::before {
                     content: "";
                     height: 3px;
                     width: 40%;
                     background: #3377EF;
                     position: absolute;
                     left: 0;
                     top: 1.3rem;
                     transition: 0.5s;
                  }

                  &:hover::before {
                     width: 100%;
                  }
               }
            }
         }
      }

      .price {
         font: 600 1.05rem 'SofiaPro';
         color: #000000;
      }
   }

   .customer-details {      
      .message {
         margin-bottom: 2.5rem;

         a {
            border-radius: 12px;
            font: normal .8rem 'SofiaPro';
            color: #FFFFFF;
            padding: .8rem 1.3rem;
            background: #3377EF;
            
            &:hover {
               background: #3377EF98;
            }
         }
      }
      
      .details {
         background: #FFFFFF;
         border-radius: 25px;
            
         .profile {
            background: #FDFDFF;
            border-radius: 20px;
            padding: 1rem 1rem 0 1rem;

            .profile-pic {
               .img {
                  padding-top: 2rem;
                  padding-bottom: 1rem;
               }

               span {
                  font: normal .9rem 'SofiaPro';
                  color: #000000;
               }

               .verify-error {
                  color: #e40d0d;
                  font: normal .8rem 'SofiaPro';
                  margin-top: .5rem;
               }
            }

            .verify-loan {
               padding: 2.5rem 2rem 4rem;

               div {
                  border-radius: 12px;
                  font: 600 .8rem 'SofiaPro';
                  cursor: pointer;

                 &:first-child {
                     color: #3377EF;
                     border: 1px solid #3377EF;
                     padding: .7rem .8rem;
                     
                     &:hover {
                        background: #3377EF98;
                        color: #ffffff;
                        border: 1px solid #3377EF98;
                     }
                  } 
                  
                  &:last-child {
                     color: #FFFFFF;
                     background: #3377EF;
                     padding: .7rem 1.7rem;
                     
                     &:hover {
                        background: #3377EF98;                           
                     }
                  }
               }
            }
         }

         .detail-tabs {
            padding: 0 2rem;
            margin-top: -1.2rem;

            ul {
               list-style: none;
               padding-left: 0;
               margin-bottom: 0;

               li {
                  position: relative;

                  &:not(:last-child) {
                     margin-right: 3.5rem;
                  }

                  div {
                     font: normal .83rem 'SofiaPro';
                     color: #606060;
                     cursor: pointer;

                     &:hover {
                        color: #3377EF;
                     }
                     
                     &.active {
                        color: #3377EF;
                        font: 600 .83rem 'SofiaPro';

                        &::before {
                           content: "";
                           height: 3px;
                           width: 50%;
                           background: #3377EF;
                           position: absolute;
                           left: 0;
                           top: 1.05rem;
                           transition: 0.5s;
                        }

                        &:hover::before {
                           width: 100%;
                        }
                     }
                  }
               }
            }
         }

         .data {
            padding: 2rem;

            h6 {
               font: 600 .8rem 'SofiaPro';
               color: #000000;
            }

            p {
               font: normal .8rem 'SofiaPro';
               color: #000000;
               margin-bottom: 1rem;
            }
         }
         .user {
            padding: .8rem 2rem;

            h6 {
               font: 600 .8rem 'SofiaPro';
               color: #000000;
            }

            p {
               font: normal .8rem 'SofiaPro';
               color: #000000;
            }
         }
      }
   }
}

.ReactModal__Overlay--after-open, .ReactModal__Overlay {
   background-color: rgba(0, 0, 0, 0.3) !important;

   .ReactModal__Content, .ReactModal__Content--after-open {
      border: none !important;
   }

   .__form {
      padding: 1.8rem 2.5rem;

      h4 {
         font: 600 1.15rem 'SofiaPro';
         color: #000000;
      }
      p {
         font: normal .72rem 'SofiaPro';
         color: #000000;
      }

      .label {
         font: normal .85rem 'SofiaPro';
         color: #000000;
      }

      .input1 {
         background: #EBEBEB;
         border-radius: 4px;
         margin-top: 1rem;
         padding: .6rem 12rem .6rem 1.3rem;
         margin-bottom: 1.2rem;

         &::placeholder {
            font: normal .8rem 'SofiaPro';
            color: #000000;
         }
      }

      .input2 {
         border: 1px solid #C4C4C4 !important;
         border-radius: 4px;
         background: none;
         padding: .6rem 12rem .6rem 1.3rem;
         margin-top: 1rem;

         &::placeholder {
            font: normal .8rem 'SofiaPro';
            color: #C4C4C4;
         }
      }

      button {
         border: none;
         background: none;
         margin: 1.5rem 0rem 1rem auto !important;
         padding: 0;

         div {
            background: #3377EF;
            border-radius: 8px;
            font: normal .8rem 'SofiaPro';
            color: #ffffff;
            padding: .8rem 1.8rem;
            
            &:hover {
               background: #3377EF98;
            }
         }
      }
   }

   .content {
      .credited {
         margin: 1.8rem auto;
      }

      p {
         font: normal .8rem 'SofiaPro';
         // text-align: center;
         color: #000000;
         padding: 0 8rem;
      }

      button {
         padding: 0;
         border: none;
         background: none;
         margin: 3.6rem auto;

         a {
            border: 1px solid #3377EF;
            border-radius: 8px;
            font: 600 .8rem 'SofiaPro';
            color: #3377EF;
            padding: .7rem 2rem;

            &:hover {
               background: #3377EF98;
               color: #ffffff;
               border: none;
            }
         }
      }
   }
}