/*========= DASHBOARD HEADER ===========*/
.header {
  height: 5rem;
  background: #F7F8FC;
  
  .dsh-brand {
    padding: 2rem 1.7rem 1rem 0;

    .dsh-name {
      .dsh-brand-name {
        font: 600 1.5rem 'SofiaPro';
        color: #000000;
      }

      .badge {
        font: 500 .8rem 'SofiaPro';
        color: #FFFFFF;
        border-radius: 50%;
        background: rgba(255, 193, 7, 0.5) !important;
      }
    }

    .profile-img {

      .notification {
        position: relative;
        color: #130F26 !important;
        border-radius: 50%;

        .ellipse {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .profile {
        margin-left: 1rem;
        cursor: pointer;
      }

      .profile-settings {
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        top: 100%;
        left: -7.5rem;
        z-index: 2;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            font: normal .8rem 'SofiaPro';
              color: #000000;
              padding: .8rem 4rem;
              border-radius: 10px;
              transition: all .3s ease;
              // display: inline-block;
              
              &:hover {
                background: #3377EF;
                color: #ffffff;
              }
            // a {
            //   font: normal .8rem 'SofiaPro';
            //   color: #000000;
            //   padding: 1rem 5rem;
            //   border-radius: 10px;
            //   transition: all .3s ease;

            //   &:hover {
            //     background: #3377EF;
            //     color: #ffffff;
            //   }
            //}
          }
        }
      }
    }
  }
}
