.shared-background {
   margin: 0;

   .background {
      background: #F7F8FC;
      height: 100%;
      width: 100%;
      
      .circle {
         position: absolute;
         border: .9rem solid #FFE607;
         height: 15.6rem;
         width: 15.6rem;
         border-radius: 50%;
         margin-top: -7.3rem;
         margin-left: -7.3rem;
         
         .sec-circle {
            border: .9rem solid #3377EF;
            height: 11.9rem;
            width: 11.9rem;         
            border-radius: 50%;
         }
      }
   }
}