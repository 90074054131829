@font-face {
  font-family: 'SofiaPro';
  src: url('./_assets/fonts/sofiapro-light.otf');
  font-weight: 300;
}
@font-face {
  font-family: 'SofiaPro';
  src: url('./_assets/fonts/SofiaProRegular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'SofiaPro';
  src: url('./_assets/fonts/SofiaPro-Bold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'SofiaPro';
  src: url('./_assets/fonts/SofiaPro-SemiBold.ttf');
  font-weight: 700;
}

$font: 'SofiaPro';

html {
  font-family: $font;

  .body {

    &.content {
      height: calc(100% - 5rem);
      overflow-y: scroll;
      background: #F7F8FC;
  
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
  
      &::-webkit-scrollbar {
      display: none; /* Chrome, Safari */
      }
    }
  
    &.full {
      height: 100%;
    }
  
    h1, h2, h3, h4, h5, h6, p{
      padding: 0;
      margin: 0;
    }
  
  }

  button, input, .input1, .input2, .input11, .input22, textarea {
    outline: none !important;
  }
  input, textarea {
    &:focus, &:active {
        &::placeholder {
          opacity: 0.5;
        }
    }
  }

  textarea {
    resize: none;
  }

  button {
    border: none;
    background: transparent;
  }

  button, input, textarea {
    outline: none !important;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .badge {
    box-shadow: none;
  }
}