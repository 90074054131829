.notice{
    position: absolute;
    top: 0;
    left: 0;
    // background-color: #ACE4B9;
    // background-color: #F51616;

    p{
        font: 600 .85rem 'SofiaPro';
        padding: .4rem 0;
        margin: 0;
    }
}
.success {
    background-color: #ACE4B9;
}
.error {
    background-color: #F51616;
}