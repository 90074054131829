.customer-page {
   .history {
      padding-top: 1rem;
      margin-bottom: 2rem;

      ul {
         list-style: none;
         padding-left: 0;
         margin-left: 0;
         margin-right: 20rem;

         li {
            position: relative;

            &:first-child {
               margin-right: 2rem;
            }

            div {
               font: 600 .9rem 'SofiaPro';
               color: #C4C4C4;
               cursor: pointer;

               &:hover {
                  color: #3377EF98;
               }

               &.active {
                  color: #3377EF;

                  &::before {
                     content: "";
                     height: 3px;
                     width: 40%;
                     background: #3377EF;
                     position: absolute;
                     left: 0;
                     top: 1.3rem;
                     transition: 0.5s;
                  }

                  &:hover::before {
                     width: 100%;
                  }
               }
            }
         }
      }

      .price {
         font: 600 1.05rem 'SofiaPro';
         color: #000000;
      }
   }

   .customer-details {      
      .message {
         margin-bottom: 2.5rem;

         a {
            border-radius: 12px;
            font: normal .8rem 'SofiaPro';
            color: #FFFFFF;
            padding: .8rem 1.3rem;
            background: #3377EF;
            
            &:hover {
               background: #3377EF98;
            }
         }
      }
      
      .details {
         background: #FFFFFF;
         border-radius: 25px;
         width: 100%;
            
         .profile {
            background: #FDFDFF;
            border-radius: 20px;
            padding: 1rem 1rem 0 1rem;

            .profile-pic {
               .img {
                  padding-top: 2rem;
                  padding-bottom: 1rem;
               }

               span {
                  font: normal .9rem 'SofiaPro';
                  color: #000000;
               }
            }

            .verify-loan {
               padding: 2.5rem 2rem 4rem;

               div {
                  border-radius: 12px;
                  font: 600 .8rem 'SofiaPro';
                  cursor: pointer;

                  &:first-child {
                     color: #3377EF;
                     border: 1px solid #3377EF;
                     padding: .7rem .8rem;
                     
                     &:hover {
                        background: #3377EF98;
                        color: #ffffff;
                        border: 1px solid #3377EF98;
                     }
                  }
                  
                  &:last-child {
                     color: #FFFFFF;
                     background: #3377EF;
                     padding: .7rem 1.7rem;
                     
                     &:hover {
                        background: #3377EF98;                           
                     }
                  }
               }
            }
         }

         .detail-tabs {
            padding: 0 2rem;
            margin-top: -1.2rem;

            ul {
               list-style: none;
               padding-left: 0;
               margin-bottom: 0;

               li {
                  position: relative;

                  &:not(:last-child) {
                     margin-right: 4.7rem;
                  }

                  div {
                     font: normal .83rem 'SofiaPro';
                     color: #606060;
                     cursor: pointer;

                     &:hover {
                        color: #3377EF;
                     }
                     
                     &.active {
                        color: #3377EF;
                        font: 600 .83rem 'SofiaPro';

                        &::before {
                           content: "";
                           height: 3px;
                           width: 50%;
                           background: #3377EF;
                           position: absolute;
                           left: 0;
                           top: 1.05rem;
                           transition: 0.5s;
                        }

                        &:hover::before {
                           width: 100%;
                        }
                     }
                  }
               }
            }
         }

         .data {
            padding: 2rem;

            h6 {
               font: 600 .8rem 'SofiaPro';
               color: #000000;
            }

            p {
               font: normal .8rem 'SofiaPro';
               color: #000000;
               margin-bottom: .5rem;
            }
         }
      }
   }
}

.customerss {
   .tab {
      ul.crumb {
         li {
            font: 600 .9rem 'SofiaPro';
            color: #29335A;
   
            &:first-child {
               cursor: pointer;
            }
   
            &:last-child {
               color: #3377EF;
            }

            .verified {
               transform: scale(.6);
            }
         }
         .price {
            font: 600 1.05rem 'SofiaPro';
            color: #000000;
         }
      }
      .verify-error {
         color: #e40d0d;
         font: normal 1rem 'SofiaPro';
         // margin-top: .5rem;
      }

      .btns {
         button {
            padding: .7rem 1.5rem;
            font: normal .9rem "SofiaPro";
            color: #ffffff;
            border-radius: 12px;
            transition: all .3s ease;
         }
         button.decline {
            background: #F51616;
            
            &:hover {
               background: #F5161698;
            }
         }
         button.verify {
            background: #3377EF;
            
            &:hover {
               background: #3377EF98;
            }
         }
      }
   }

   .detail {
      .inner {
         background: #FFFFFF;
         border-radius: 20px;

         .img {
            height: 2.5rem;
            width: 2.5rem;
            overflow: hidden;
            border-radius: 5px;
            // background: #bbb;

            img {
               object-fit: cover;
            }
         }

         .name-info {
            h6 {
               font: 600 .8rem 'SofiaPro';
               color: #29335A;
            }
            p {
               font: normal .7rem 'SofiaPro';
               color: #545F8A;
            }
         }

         ul.lists {
            li {
               border: 1px solid #E5E5E5;
               border-radius: 2px;
               cursor: pointer;
               transition: all .3s ease-in;
               
               .icon {
                  height: 1.7rem;
                  width: 1.7rem;
                  border-radius: 50%;
                  background: #3377EF;
               }
               
               &:hover {                  
                  border: 1px solid #3377EF98;
               }
               &.active {                  
                  border: 1px solid #3377EF;
               }
            }
         }
      }

      .content {
         background: #FFFFFF;
         border-radius: 20px;

         .basic-info, .address, .kin, .work-info, 
         .card-details {
            .__form {
               h5 {
                  font: 600 1.1rem "SofiaPro";
                  color: #29335A;
               }
               p {
                  font: 600 .7rem "SofiaPro";
                  color: #545F8A;
               }
               .label {
                  font: 600 .7rem "SofiaPro";
                  color: #000000;
               }
               .input {
                  font: normal .7rem "SofiaPro";
                  padding: .7rem 1rem;
                  border: 1px solid #C4C4C4 !important;
                  border-radius: 5px;
                  
                  &::placeholder {
                     color: #606060;
                  }
               }
               .icon__master {
                  right: 4.5%;
                  bottom: 25%;
               }
               button {
                  padding-top: .7rem;
                  padding-bottom: .7rem;
                  background: #3377EF;
                  border-radius: 10px;
                  transition: background .3s ease;
                  
                  &:hover {
                     background: #3377EF98;                     
                  }

                  p {
                     font: normal .7rem "SofiaPro";
                     color: #ffffff;
                  }
               }
            }
         }
      }
   }
}

.ReactModal__Overlay--after-open, .ReactModal__Overlay {
   background-color: rgba(0, 0, 0, 0.3) !important;

   .ReactModal__Content, .ReactModal__Content--after-open {
      border: none !important;

      &::-webkit-scrollbar {
         width: 6px;
      }
      &::-webkit-scrollbar-track {
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         background-color: #aaa;
         border-radius: 8px;
      }
   }

   .__form {
      // height
      padding: 1.8rem 2.5rem;

      h4 {
         font: 600 1.15rem 'SofiaPro';
         color: #000000;
      }
      p {
         font: normal .72rem 'SofiaPro';
         color: #000000;
      }

      .label {
         font: normal .85rem 'SofiaPro';
         color: #000000;
      }

      .input1 {
         background: #EBEBEB;
         border-radius: 4px;
         margin-top: 1rem;
         padding: .6rem 12rem .6rem 1.3rem;
         margin-top: .2rem !important;
         margin-bottom: .8rem !important;

         &::placeholder {
            font: normal .8rem 'SofiaPro';
            color: #000000;
         }
      }

      label, input {
         margin: 0;
         padding: 0;
      }

      .input2 {
         border: 1px solid #C4C4C4 !important;
         border-radius: 4px;
         background: none;
         padding: .6rem 12rem .6rem 1.3rem;
         margin-top: .2rem !important;
         margin-bottom: .8rem !important;

         &::placeholder {
            font: normal .8rem 'SofiaPro';
            color: #C4C4C4;
         }
      }

      button {
         border: none;
         background: none;
         // margin: 1.5rem 0rem 1rem auto !important;
         margin: 1rem 0;
         padding: 0;
         width: max-content;

         .button {
            background: #3377EF;
            border-radius: 8px;
            font: normal .8rem 'SofiaPro';
            color: #ffffff;
            width: max-content;
            // padding: .8rem 1.8rem;
            
            &:hover {
               background: #3377EF98;
            }
         }
      }
   }

   .content {
      .credited {
         margin: 1.8rem auto;
      }

      p {
         font: normal .8rem 'SofiaPro';
         // text-align: center;
         color: #000000;
         padding: 0 8rem;
      }

      button {
         padding: 0;
         border: none;
         background: none;
         margin: 1.6rem auto;
         
         a {
            border: 1px solid #3377EF;
            border-radius: 8px;
            font: 600 .8rem 'SofiaPro';
            color: #3377EF;
            padding: .7rem 2rem;
            
            &:hover {
               background: #3377EF98;
               color: #ffffff;
               border: none;
            }
         }
      }
      .py-2 {
         margin-bottom: 1rem;
      }
      .content-btn {
         margin: 1.6rem auto;
      }
   }
}