.admin-page {
   padding: 2.6rem 0;
   padding-right: 2.5rem;

   .add-new {
      a {
         background: #EBEBEB;
         border-radius: 12px;
         font: 600 .8rem 'SofiaPro';
         color: #000000;
         padding: .75rem 2.5rem;
         
         &:hover {
            background: #EBEBEB95;
         }
      }
   }

   .admins {

      .admin {
         .admin-inner {
            background: #FFFFFF;
            border-radius: 10px;

            .dot {
               right: 1rem;

               .icon {
                  transform: scale(.7);
                  cursor: pointer;
               }
               ul.drop {
                  width: 5rem;
                  font: 600 .8rem 'SofiaPro';
                  background: #FFFFFF;
                  box-shadow: 0px 8px 24px rgba(51, 119, 239, 0.15);
                  overflow: hidden;
                  top: 2rem;
                  right: 0;
                  border-radius: 10px;
                  z-index: 2;
                  
                  li {
                     border-radius: 10px;
                     list-style: none;
                     
                     &:first-child {
                        a {
                           padding: .6rem 0;
                           color: #3377EF;
                           
                           &:hover {
                              background: #3377EF;
                              color: #ffffff;
                           }
                        }
                     }
                     
                     &:last-child {
                        a {
                           padding: .6rem 0;
                           color: #E2574C;
                           border-radius: 10px;
                           
                           &:hover {
                              background: #E2574C;
                              color: #ffffff;
                           }
                        }
                     }
                  }
               }
            }

            .name {
               h4 {
                  font: 600 .95rem 'SofiaPro';
                  color: #000000;
               }

               p {
                  font: 600 .7rem 'SofiaPro';
                  color: #C4C4C4;
               }
            }
         }
      }
   }

   .button {
      background: #3377EF;
      border-radius: 10px;
      font: normal .8rem 'SofiaPro';
      color: #FFFFFF;
      padding: .8rem 2.5rem;
      cursor: pointer;
      width: max-content;
      
      &:hover {
         background: #3377EF98;
      }
   }

   .customer-list {
      background: #FFFFFF;
      border-radius: 20px;

      .profile {
         padding-left: 2rem !important;

         thead {
            
            tr {
               border-bottom: 1px solid rgba(0,0,0,.1);
               
               th {
                  font: 600 .85rem 'SofiaPro';
                  color: #000000;
                  padding: 1.5rem 1rem 1rem 1.5rem;
               }
            }
         }
         
         tbody {
            .d-table-row {
               &:nth-child(odd) {                  
                  background: #fbfcff;
               }

               th ,td {
                  font: normal .8rem 'SofiaPro';
                  // padding: 0.85rem 1.2rem;
                  // padding: 1.2rem;
                  color: #000000;
               }

               th {
                  span {
                     padding-left: .6rem;
                  }
               }

               &:hover {
                  background: #F7F8FC;
               }

               .form-check {
                  padding: 0;

                  input {
                     margin: 0;
                     margin-left: -.8rem;
                  }
               }
            }

            .t-row {
               th,td {
                  padding: 1.7rem 0 1.7rem 2rem;
               }
            }
         }
      }
   }

   .search-filter {
      padding-bottom: 2rem;
      // margin-bottom: 4rem;

      .search {
         .input-group {
            input {
               background: #FFFFFF;
               border-radius: 10px 0 0 10px;
               border: none;
               padding: .7rem 4rem .7rem 1rem;

               &::placeholder {
                  font: normal .8rem 'SofiaPro';
                  color: #C4C4C4;
               }
            }

            .i-x {
               margin-left: -3rem;
            }

            .i-search {
               background: #3377EF;
               color: #fff;
               border-radius: 0px 10px 10px 0px;
               padding: .7rem 1rem;
               margin-left: 2rem;
               cursor: pointer;
               transition: all 0.3s ease-in;
               
               &:hover {
                  background: #3377EF98;
               }
            }
         }
      }

      .filter {
         .menu {
            border-radius: 13px;
            font: 600 .8rem 'SofiaPro';
            padding: .8rem 1rem;
            color: #3377EF;
            cursor: pointer;

            &:first-child {
               border: 1px solid #3377EF;
               transition: all .4s ease;
               
               &:hover {
                  background: #3377EF;
                  color: #FFFFFF;
               }
            }
         }

         .menu2 {
            background: #3377EF;
            color: #ffffff;
            margin-left: 1rem;
            border: 1px solid #3377EF;
            transition: all .4s ease;
            
            &:hover {
               background: transparent;
               color: #3377EF;
               border: 1px solid #3377EF;
            }
         }

         .collaps {
            display: inline-flex;
            position: absolute;
            left: 0;
            // top: 18%;
            margin-bottom: 2rem;
               
            .collaps-item {
               background: #FFFFFF;
               border-radius: 10px;
               font: normal .9rem 'SofiaPro';
               color: #606060;
               padding: .7rem 1.5rem;
               cursor: pointer;

               .status-list {
                  top: 100%;
                  left: 0;
                  right: 0;
                  border-radius: 10px;
                  background: #ffffff;
                  box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
                  
                  ul {
                     list-style: none;
                     margin-bottom: 0;
                     padding-left: 0;
                     
                     li {
                        a {
                           font: normal .85rem 'SofiaPro';
                           border-radius: 10px;
                           color: #000000;
                           padding: .8rem 1.5rem;
                           
                           &:hover {
                              background: #3377EF;
                              color: #FFFFFF;
                           }
                        }
                     }
                  }
               }

               .date {
                  background: #ffffff;
                  top: 100%;
                  left: 0;
                  border-radius: 10px;
                  box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
               }
            }

            .date-range {
               padding: 1rem .2rem ;

               .input {
                  background: #ffffff;
                  // border: none;

                  &::placeholder {
                     color: #000000;
                     padding: .5rem;
                  }
               }

               .date-range_arrow {
                  color: #fff !important;
               }
            }
         }
      }
   }
}