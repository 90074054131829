.dashboard-page {
  padding: 1rem 0;
  padding-right: 1.7rem;

  .cards {
    padding-top: 2rem;
    padding-bottom: 2rem;
  
    .card-item {
      width: 25%;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 4px 25px -6px rgba(113, 95, 95, 0.1);

      &:hover {
        box-shadow: 0px 4px 25px -6px rgba(113, 95, 95, 0.1);
      }
  
      .card-inner {
        padding: 1.5rem;
        
        h6 {
          font: 600 1.2rem 'SofiaPro';
          color: #C4C4C4;
          padding-bottom: 1rem;
        }
        
        p {
          font: 600 1.3rem 'SofiaPro';
          color: #000000;
        }
      }
  
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .chart-withdrawal {
    width: 62%;
    box-shadow: 0px 4px 25px -6px rgba(113, 95, 95, 0.1);

  }

  .withdrawal {
    width: 38%;
  }

  .chart-withdrawal, .withdrawal {

    .profit {
      background: #FFFFFF;
      border-radius: 25px;
      padding-top: 1.5rem;
      
      .circle {
        background: #FFFFFF;
        color: #000000;
        font: 600 1.5rem 'SofiaPro';
        border: 1px solid #3377EF;
        border-radius: 50%;
        height: 7rem;
        width: 7rem;
        margin: auto;
      }

      p {
        font: normal 1.2rem 'SofiaPro';
        color: #000000;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
      }
    }

    .chart {
      background: #FFFFFF;
      border-radius: 25px;
      padding: 1rem;

      .collaps {
        display: inline-flex;
        position: absolute;
        right: 1.5rem;
        // left: 0;
        top: 1.5rem;
        margin-bottom: 2rem;
            
        .collaps-item {
          background: #FFFFFF;
          font: normal .8rem 'SofiaPro';
          color: #606060;
          padding: .5rem 1.2rem;
          cursor: pointer;
          border: 1px solid #606060;
          box-sizing: border-box;
          border-radius: 5px;

          .status-list {
            top: 105%;
            left: 0;
            right: 0;
            border-radius: 5px;
            background: #ffffff;
            box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
            
            ul {
              list-style: none;
              margin-bottom: 0;
              padding-left: 0;
              
              li {
                a {
                  font: normal .85rem 'SofiaPro';
                  border-radius: 5px;
                  color: #000000;
                  padding: .8rem 1.5rem;
                  
                  &:hover {
                    background: #3377EF;
                    color: #FFFFFF;
                  }
                }
              }
            }
          }

          .date {
            background: #ffffff;
            top: 100%;
            left: 0;
            border-radius: 10px;
            box-shadow: 0px 4px 10px rgba(51, 119, 239, 0.05);
          }
        }
      }

      .bar-chart {
        margin-top: 4.6rem;
      }
    }

    .loan, .earning, .settlement {
      box-shadow: 0px 4px 25px -6px rgba(113, 95, 95, 0.1);

      .withdraw {
        margin-top: 1.5rem;

        p {
          font: 600 1.05rem 'SofiaPro';
          color: #000000;

          .ml-2 {
            font: 500 .8rem 'SofiaPro';
            color: #FFFFFF;
            border-radius: 50%;
            background: #3377EF !important;
            cursor: pointer;
          }
        }

        a {
          font: 600 .75rem 'SofiaPro';
          color: #606060;
          border: 1px solid #606060;
          border-radius: 4px;
          padding: .5rem 2rem;

          &:hover {
            color: #ffffff;
            background: #606060;
          }
        }
      }

      .withdrawal-inner {
        background: #FFFFFF;
        border-radius: 25px;
        padding: .2rem 1.5rem !important;

        thead {
          th {
            font: 600 .95rem 'SofiaPro';
            color: #000000;

            &:not(:last-child) {
              padding-right: 6.9rem;
            }
          }
        }

        tbody {
          .d-table-row:hover {
            background: #F7F8FC;
          }

          th, td {
            font: normal .8rem 'SofiaPro';
            color: #000000;
            padding-top: .82rem;
            padding-bottom: .82rem;
          }
        }
      }

      .data {
        margin: 2rem 0;
      }
      p.data {
        font: normal .95rem 'SofiaPro';
        margin-bottom: 3rem;
      }

      .data2 {
        margin: 0;
      }
      p.data2 {
        font: normal .95rem 'SofiaPro';
        margin-bottom: 2rem;
      }
    }
  }
}


