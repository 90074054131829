/*===== DASHBOARD SIDEBAR COMPONENT ==============*/
.sidebar {
  position: sticky;
  width: 320px !important;
  background: #F7F8FC;
  padding: 2.1rem 2.5rem 2rem 1.8rem;
  box-shadow: 0px 4px 25px -6px rgba(113, 95, 95, 0.1);

  .sidebar-inner {
    border-radius: 20px;
    background: #FFFFFF;

    .logo {
      a {
        margin: 2.5rem;
        font: 900 .95rem 'SofiaPro';
        color: #000000;
        text-transform: uppercase;
        transition: all .3s ease;

        &:hover {
          color: #3377EF99;
        }
      }
    }

    .sidebar-panel {
      font: normal 0.9rem 'SofiaPro';

      .list-item {
        background: transparent !important;
        border: none !important;
    
        a {
          color: #000000;
          padding: .7rem 2.7rem .85rem;
          transition: all 0.4s ease;

          .dropdownmenu {
            display: none;
            z-index: 2;
            background: #FFFFFF;
            box-shadow: 0px 8px 24px rgba(51, 119, 239, 0.15);
            left: 1.7rem;
            top: 2.8rem !important;

            li {
              list-style: none;

              a {
                &.active {
                background: #3377EF !important;
                color: #ffffff;
          }
              }
            }
          }
    
          &:hover {
            background: #3377EF98 !important;
            color: #ffffff;

            .dropdownmenu {
              display: block;
            }
          }
    
          &.active {
            background: #3377EF !important;
            color: #ffffff;
          }
        }
    
        .mr-2 {
          margin-right: .5rem !important;
        }
      }
      .mb-5 {
        margin-bottom: 4rem !important;
      }
    }
  }
}
